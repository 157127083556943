/**
 * Place any jQuery/helper plugins in here.
 */
$(function () {
    /**
     * Checkbox tree for permission selecting
     */
    let permissionTree = $('.permission-tree :checkbox');

    permissionTree.on('click change', function (){
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        } else {
            $(this).siblings('ul').find('input[type="checkbox"]').removeAttr('checked').removeAttr('disabled');
        }
    });

    permissionTree.each(function () {
        if($(this).is(':checked')) {
            $(this).siblings('ul').find('input[type="checkbox"]').attr('checked', true).attr('disabled', true);
        }
    });

    /**
     * Disable submit inputs in the given form
     *
     * @param form
     */
    function disableSubmitButtons(form) {
        form.find('input[type="submit"]').attr('disabled', true);
        form.find('button[type="submit"]').attr('disabled', true);
    }

    /**
     * Enable the submit inputs in a given form
     *
     * @param form
     */
    function enableSubmitButtons(form) {
        form.find('input[type="submit"]').removeAttr('disabled');
        form.find('button[type="submit"]').removeAttr('disabled');
    }

    /**
     * Disable all submit buttons once clicked
     */
    $('form').submit(function () {
        disableSubmitButtons($(this));
        return true;
    });

    /**
     * Add a confirmation to a delete button/form
     */
    $('body').on('submit', 'form[name=delete-item]', function(e) {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to delete this item?',
            showCancelButton: true,
            confirmButtonText: 'Confirm Delete',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        }).then((result) => {
            if (result.value) {
                this.submit()
            } else {
                enableSubmitButtons($(this));
            }
        });
    })
        .on('submit', 'form[name=confirm-item]', function (e) {
            e.preventDefault();

            Swal.fire({
                title: 'Are you sure you want to do this?',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                icon: 'warning'
            }).then((result) => {
                if (result.value) {
                    this.submit()
                } else {
                    enableSubmitButtons($(this));
                }
            });
        })
        .on('click', 'a[name=confirm-item]', function (e) {
        /**
         * Add an 'are you sure' pop-up to any button/link
         */
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to do this?',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            icon: 'info',
        }).then((result) => {
            result.value && window.location.assign($(this).attr('href'));
        });
    });

    // Remember tab on page load
    $('a[data-toggle="tab"], a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        let hash = $(e.target).attr('href');
        history.pushState ? history.pushState(null, null, hash) : location.hash = hash;
    });

    let hash = window.location.hash;
    if (hash) {
        $('.nav-link[href="'+hash+'"]').tab('show');
    }

    // Enable tooltips everywhere
    $('[data-toggle="tooltip"]').tooltip();

    // Core Function

    function waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
    function showCopy(e,id) {
        e.insertAdjacentHTML('afterend',`<small class="d-inline-block font-weight-bold text-muted" id="copy_`+id+`">Copy</small>`)
    }
    function hideCopy(e) {
        e.nextElementSibling.remove()
    }
    function clickCopy(selector,variable) {
        var copyText = variable;
        var originalText = document.querySelector(selector).textContent;
        document.querySelector(selector).textContent = 'Copied!';
        setTimeout(() => {
            var _selector = document.querySelector(selector)
            if(_selector) {
                _selector.textContent = originalText;
            }
        }, 1000);
        navigator.clipboard.writeText(copyText);
    }

    // Copy Hover

    waitForElm('.copy-hover').then((elm) => {
        let copy_hover = document.querySelectorAll('.copy-hover')
        copy_hover.forEach(e => {
            e.onmouseover = function(){ showCopy(e,e.getAttribute('data-copy-id')) }
            e.onclick = function(){ clickCopy('#copy_'+e.getAttribute('data-copy-id'),e.getAttribute('data-copy-text')) }
            e.onmouseleave = function(){ hideCopy(e) }
        });
    });

    // Copy Click

    waitForElm('.copy-click').then((elm) => {
        let copy_click = document.querySelectorAll('.copy-click')
        copy_click.forEach(e => {
            e.onclick = function(){ clickCopy('#copy_'+e.getAttribute('data-copy-id'),e.getAttribute('data-copy-text')) }
        });
    });

    window.randomHash = (length = 8) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    $('.select2').select2()
    $('.select2-email').select2({
        tags: true,
        minimumInputLength: 1,
        ajax: {
            processResults: function (data, params) {
                let new_results = []
                data.results.forEach(result => {
                    new_results.push({id: result.text, text: result.text})
                })
                return {
                    results: new_results,
                    pagination: {
                        more: false
                    }
                }
            }
        }
    })
    $('.select2-tags-box').select2({
        minimumInputLength: 1,
        tags: true,
        tokenSeparators: [','],
        ajax: {
            processResults: function (data, params) {
                let new_results = []
                data.results.forEach(result => {
                    new_results.push({id: result.text, text: result.text})
                })
                return {
                    results: new_results,
                    pagination: {
                        more: false
                    }
                }
            }
        }
    })
    $(".select-filter").select2();
});
